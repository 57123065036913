import * as React from 'react';
import memoizeOne from 'memoize-one';
import devWarning from '../_util/devWarning';
import { changeConfirmLocale } from '../modal/locale';
import LocaleContext from './context';
export const ANT_MARK = 'internalMark';
export default class LocaleProvider extends React.Component {
    constructor(props) {
        super(props);
        changeConfirmLocale(props.locale && props.locale.Modal);
        if (process.env.NODE_ENV !== 'production')
            devWarning(props._ANT_MARK__ === ANT_MARK, 'LocaleProvider', '`LocaleProvider` is deprecated. Please use `locale` with `ConfigProvider` instead: http://u.ant.design/locale');
    }
    componentDidMount() {
        changeConfirmLocale(this.props.locale && this.props.locale.Modal);
    }
    componentDidUpdate(prevProps) {
        const { locale } = this.props;
        if (prevProps.locale !== locale) {
            changeConfirmLocale(locale && locale.Modal);
        }
    }
    componentWillUnmount() {
        changeConfirmLocale();
    }
    render() {
        const { locale, children } = this.props;
        const contextValue = memoizeOne(localeValue => (Object.assign(Object.assign({}, localeValue), { exist: true })))(locale);
        return <LocaleContext.Provider value={contextValue}>{children}</LocaleContext.Provider>;
    }
}
LocaleProvider.defaultProps = {
    locale: {},
};
