import * as React from 'react';
import Button from '../button';
import { convertLegacyProps } from '../button/button';
function isThenable(thing) {
    return !!(thing && !!thing.then);
}
const ActionButton = props => {
    const clickedRef = React.useRef(false);
    const ref = React.useRef();
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        let timeoutId;
        if (props.autoFocus) {
            const $this = ref.current;
            timeoutId = setTimeout(() => $this.focus());
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, []);
    const handlePromiseOnOk = (returnValueOfOnOk) => {
        const { close } = props;
        if (!isThenable(returnValueOfOnOk)) {
            return;
        }
        setLoading(true);
        returnValueOfOnOk.then((...args) => {
            setLoading(false);
            close(...args);
            clickedRef.current = false;
        }, (e) => {
            // Emit error when catch promise reject
            // eslint-disable-next-line no-console
            console.error(e);
            // See: https://github.com/ant-design/ant-design/issues/6183
            setLoading(false);
            clickedRef.current = false;
        });
    };
    const onClick = (e) => {
        const { actionFn, close } = props;
        if (clickedRef.current) {
            return;
        }
        clickedRef.current = true;
        if (!actionFn) {
            close();
            return;
        }
        let returnValueOfOnOk;
        if (props.emitEvent) {
            returnValueOfOnOk = actionFn(e);
            if (props.quitOnNullishReturnValue && !isThenable(returnValueOfOnOk)) {
                clickedRef.current = false;
                close(e);
                return;
            }
        }
        else if (actionFn.length) {
            returnValueOfOnOk = actionFn(close);
            // https://github.com/ant-design/ant-design/issues/23358
            clickedRef.current = false;
        }
        else {
            returnValueOfOnOk = actionFn();
            if (!returnValueOfOnOk) {
                close();
                return;
            }
        }
        handlePromiseOnOk(returnValueOfOnOk);
    };
    const { type, children, prefixCls, buttonProps } = props;
    return (<Button {...convertLegacyProps(type)} onClick={onClick} loading={loading} prefixCls={prefixCls} {...buttonProps} ref={ref}>
      {children}
    </Button>);
};
export default ActionButton;
